<template>
  <div class="card-agreement">
    <v-container class="relative">
      <h1>Attiva Convenzione: Registrazione Coop Trentino Card</h1>
      <h4>
        Completa questi campi per finalizzare la registrazione, ci vorranno
        pochi istanti per essere abilitato alla convenzione.
      </h4>

      <v-stepper v-model="step" elevation="0" outlined class="pa-2 my-4">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            Coop Trentino Card
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="step > 2" step="2">
            Dati di domicilio
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            Account e privacy
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row class="d-flex align-center">
              <v-col cols="12" md="4" lg="4">
                <img
                  class="card-convenzione-img"
                  alt="Immagine della card convenzione"
                  src="/img_layout/card-convenzione.png"/></v-col
              ><v-col cols="12" md="8" lg="8">
                <v-form ref="form" v-model="isValid">
                  <v-text-field
                    v-model="fidelityCard"
                    color="primary"
                    label="Codice Coop Trentino Card"
                    outlined
                    dense
                    :rules="requiredRules"
                    required
                  ></v-text-field>
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        dense
                        block
                        large
                        class="my-2"
                        elevation="0"
                        :to="{
                          name: 'RegistrationUser',
                          path: '/registration-user'
                        }"
                        >Indietro</v-btn
                      >
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        dense
                        block
                        large
                        color="primary"
                        class="my-2"
                        elevation="0"
                        @click="findUser"
                        >Conferma</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <UserData
              :userData.sync="userData"
              :supportData.sync="supportData"
              :hasFidelityCard="true"
              :askFirstName="askFirstName"
              :askLastName="askLastName"
              :askFiscalCode="askFiscalCode"
              :isRegisterWithCard="isRegisterWithCard"
              :isFiscalCode.sync="isFiscalCode"
              :registrationType="registrationType"
              :isSocialLogin="isSocialLogin"
              @back="goToPreviousStep(true)"
              @submit="onUserDataSubmit"
            />
          </v-stepper-content>

          <v-stepper-content step="3">
            <UserPasswordAndPrivacy
              class="mt-5"
              :userData.sync="userData"
              :supportData.sync="supportData"
              :response="response"
              @back="goToPreviousStep"
              @submit="registerUser"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>
  </div>
</template>
<style lang="scss">
.card-agreement {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .card-convenzione-img {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
<script>
import UserData from "@/components/profile/form/UserData.vue";
import UserPasswordAndPrivacy from "@/components/profile/form/UserPasswordAndPrivacy";
import clickHandler from "~/mixins/clickHandler";
import { setPersonInfo } from "~/service/ebsn";
import { requiredValue, isLength } from "~/validator/validationRules";
import aliRegistrationService from "@/service/aliRegistrationService";
import SecurityService from "~/service/securityService";

export default {
  name: "CardAgreement",
  components: { UserData, UserPasswordAndPrivacy },
  data() {
    return {
      step: 1,
      response: {},
      isValid: false,
      fidelityCard: null,

      supportData: {
        privacy1: true,
        privacy2: true,
        bornCountry: {
          name: null
        }
      },
      userData: {
        fidelityCard: null,
        email: null,
        phone: null,
        billingAddress: {},
        person: {
          firstName: null,
          lastName: null,
          fiscalCode: null,
          gender: null,
          birthDate: null,
          birthPlace: null,
          personInfos: [
            { personInfoTypeId: 6, value: true },
            { personInfoTypeId: 7, value: true },
            { personInfoTypeId: 8, value: true }
            // { personInfoTypeId: 14, value: "1" }
          ]
        }
      },
      checkAgreement: false,
      askFirstName: true,
      askLastName: true,
      askFiscalCode: true,
      isRegisterWithCard: null,
      isFiscalCode: true,
      registrationType: "OldUser",
      isSocialLogin: false,

      requiredRules: [
        requiredValue("La Coop Trentino Card è obbligatoria"),
        isLength(13, "Il codice deve essere lungo 13 caratteri")
      ]
    };
  },
  mixins: [clickHandler],
  mounted() {},
  methods: {
    findUser() {
      this.userData.fidelityCard = this.fidelityCard;
      let _this = this;
      aliRegistrationService
        .findUser(this.userData, this.checkAgreement)
        .then(function(response) {
          if (response.response.status == 0) {
            _this.fidelityFound = true;
            _this.askFiscalCode = response.data.askFiscalCode;
            _this.askFirstName = response.data.askFirstName;
            _this.askLastName = response.data.askLastName;

            if (!_this.askFiscalCode) {
              _this.userData.person.gender = "F";
            }
            _this.goToNextStep(1);
          }
          _this.response = response;
        });
    },

    registerUser(oldCustomer, validBornDate) {
      if (validBornDate === false) {
        this.response = {
          errors: [{ error: "Formato della data di nascita non corretto" }]
        };
        return;
      }
      this.userData.checkAgreement = this.checkAgreement;
      this.userData.force = this.response.status === 2;
      let force = this.response.status === 2;
      for (let i = 0; i < this.userData.person.personInfos.length; i++) {
        if (this.userData.person.personInfos[i].personInfoTypeId === 7) {
          this.userData.person.personInfos[i].value = 7;
        }
        if (this.userData.person.personInfos[i].personInfoTypeId === 8) {
          this.userData.person.personInfos[i].value = 8;
        }
      }
      setPersonInfo(this.userData.person, 7, this.supportData.privacy1);
      setPersonInfo(this.userData.person, 8, this.supportData.privacy2);

      let _this = this;
      //TODO: verificare chiamata ad AnalyticsService
      aliRegistrationService.registerUser(this.userData).then(function(data) {
        if (data.response.status == 0) {
          // AnalyticsService.trackEvent(
          //   "Registration",
          //   "Complete",
          //   _this.logTrackStr + _this.detectUserDetails()
          // );

          SecurityService.refreshLogin(true).then(function() {
            if (_this.isSocialLogin) {
              _this.$router.push({
                path: "/registration-social-completed",
                name: "RegistrationSocialCompleted",
                params: {
                  mail: _this.userData.login,
                  is_social_reg: true
                }
              });
            }
            if (force || _this.checkAgreement) {
              _this.$router.push({
                name: "RegistrationCompletedAgreement",
                params: { mail: _this.userData.email }
              });
            } else {
              _this.$router.push({
                name: "RegistrationCompleted",
                params: { mail: _this.userData.email }
              });
            }
          });
        } else {
          let error = data.response.errors[0].error;
          global.EventBus.$emit("error", {
            message: error
          });
          // _this.logTrackStr = _this.logTrackStr + "-Error";
          // AnalyticsService.trackEvent(
          //   "Registration",
          //   "Error",
          //   _this.trackId + " " + error
          // );
        }
      });
    },

    onUserDataSubmit() {
      this.goToNextStep();
    },

    goToNextStep() {
      if (!this.userData.fidelityCard) {
        this.resetFormFields(this.isFiscalCode);
      }
      this.step = this.step + 1;
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },

    goToPreviousStep(skipStepZero) {
      if (skipStepZero) {
        this.$router.push({ path: "/registration-user" });
      } else {
        this.step = this.step - 1;
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        });
      }
    },

    resetFormFields(isFiscalCode) {
      if (this.formStepId == 1) {
        if (isFiscalCode) {
          this.birthDate = null;
          this.userData.person.birthPlace = null;
        } else {
          this.userData.person.fiscalCode = null;
        }
      }
    }
  },

  computed: {}
};
</script>
